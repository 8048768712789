<template>
	<div class="main-contents">
		<div class="tit">프로젝트 담당</div>
		<div class="search-box interval">
			<SelectComp class="wd07" list="Y:승인,N:미승인" v-model="srchFilter.apprYn" title="프로젝트 담당 승인여부" :isAll="true" /><nbsp/>
			<SelectComp list="corp:수요기업명,dept:부서명,posi:직급,mber:담당자명" v-model="srchFilter.srchDiv" @change="placeholderTxt = $event.target.selectedOptions[0].label" />
			<InputComp v-model="srchFilter.srchTxt" :placeholder="placeholderTxt" @keyup.enter="getAdmDcoPmgMberList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getAdmDcoPmgMberList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3 sp">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4.1%">
					<col width="8%">
					<col width="8%">
					<col width="17%">
					<col width="17%">
					<col width="17%">
					<col width="9%">
					<col width="8%">
					<col width="10%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>담당자명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>수요기업명</th>
						<th>부서명</th>
						<th>직급</th>
						<th>회원가입일</th>
						<th>프로젝트담당 승인</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="dcoPmgList.length > 0">
						<tr v-for="(row, rowIdx) in dcoPmgList" :key="rowIdx" :class="row.mberStatusCd == '02' ? 'new' : ''">
							<!-- <td class="score"><div class="new" v-if="row.mberStatusCd == '02'">new</div>{{rowIdx + 1}}</td> -->
							<td class="score"><div class="new" v-if="row.mberStatusCd == '02'">new</div>{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
							<td class="score name" @click="openPopup(row.mberSeq)">{{row.mberNm}} &#9654;</td>
							<td class="score">{{row.mberSeq}}</td>
							<td class="score">{{row.loginId}}</td>
							<td class="score">{{row.corpNm}}</td>
							<td class="score">{{row.deptNm}}</td>
							<td class="score">{{row.positionNm}}</td>
							<td class="score">{{row.regYmd | date('yyyy.MM.dd')}}</td>
							<td class="score apvl">{{row.mberApprYn == 'Y' ? '승인' : '미승인'}}</td>
						</tr>
					</template>
					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="9" class="none">담당자가 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			srchFilter : {
				apprYn : '',
				srchDiv : 'corp',
				srchTxt : '',
				pageIndex : 1
			},

			pageInfo : {},
			dcoPmgList : [],

			placeholderTxt : '수요기업명',
		}
	},

	components : {
		pagingComp
	},

	mounted() {
		this.getAdmDcoPmgMberList();
	},

	methods : {
		getAdmDcoPmgMberList(div) {
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			this.$.httpPost('/api/mem/adm/dco/getAdmDcoPmgMberList', param)
				.then(res => {
					//console.log('getAdmDcoPmgMberList RESULT', res);

					this.dcoPmgList = res.data.dcoPmgList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		// 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getAdmDcoPmgMberList();
		},

		openPopup(seq) {
			var param = {};
			param.reqMberSeq = seq;
			param.div = 'pmg';

			this.$.popup('/adm/mem/MEM923P02', param);
		}
	}
}
</script>